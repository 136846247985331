.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
}

.logo-container {
    margin-bottom: 20px;
}

.logo-text {
    font-size: 130px;
    color: #E8E8E8;
    font-weight: bold;
    line-height: 1;
}

.welcome-container {
    text-align: center;
    margin-bottom: 30px;
    color: #71797E;
}

.welcome-container h5 {
    font-weight: bold;
    margin-bottom: 5px;
}

.form-container {
    width: 100%;
    max-width: 300px;
}

.login-button {
    background-color: #3963af !important;
    padding: 10px;
    margin-top: 10px;
    text-transform: none;
}

.message {
    margin-top: 15px;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
}

.message.error {
    color: #d32f2f;
    background-color: #fdecea;
}

.message.success {
    color: #388e3c;
    background-color: #ebf5eb;
}

.footer {
    margin-top: 30px;
    color: #777;
    font-size: 12px;
}

@media (max-width: 600px) {
    .logo-text {
        font-size: 100px;
    }
    
    .welcome-container h5 {
        font-size: 18px;
    }
}